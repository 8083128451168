import { graphql, Link } from 'gatsby';
import * as React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import FancyTitle from '../components/fancy-title';
import LayoutConsumer from '../components/layout-context';
import Seo from '../components/seo';
import ImagePane from '../components/image-pane';

function ProductPage({ data }) {
  const ProductEdges = data.allProductsYaml.edges;
  const productsPage = {
    meta_title: 'Unsere Apps im Shopify App Store',
    meta_description: 'Unsere Apps im Shopify App Store helfen Ihnen dabei, EU-Gesetze und -Verordnungen einzuhalten. ✓ Latori Best Price Indicator ✓ Tax Exempt Manager',
    title: 'Shopify Apps von Latori',
    description: 'Um regelmäßig auftretende Pain Points von Shopify-Händlern lösen zu können, entwickeln unsere Developer Private und Public Apps für unsere Kunden. Aktuell finden Sie folgende Apps von Latori im Shopify App Store:',
  };

  return (
    <LayoutConsumer>
      {({ set }) => {
        set({ pageTitle: productsPage.title, fullWidth: true });
        return (
          <div className="px-4 lg:px-16 pt-20 lg:pt-36 pb-[100px] lg:pb-60">
            <Seo title={productsPage.meta_title} description={productsPage?.meta_description} />
            <h1 className="sr-only">{productsPage.meta_title}</h1>
            <FancyTitle text={productsPage.title} tag="h1" />
            <p className="mb-10">{productsPage.description}</p>

            { ProductEdges && ProductEdges.map((edge, index) => {
              const product = edge.node;
              const image = getImage(product.image);
              return (
                <div
                  key={product.slug}
                  id={product.slug}
                  className={`${index > 0 ? 'mt-[100px] lg:mt-20' : 'lg:mt-20'}`}
                >
                  <ImagePane
                    image={(
                      <Link to={`/products/${product.slug}`}>
                        <div>
                          { image
                            && (
                            <GatsbyImage
                              image={image}
                              alt={product.title}
                              className="w-full"
                            />
                            )}
                        </div>
                      </Link>
                    )}
                    desktopImagePosition={index % 2 === 0 ? 'right' : 'left'}
                    title={(
                      <FancyTitle
                        text={product.title}
                        tag="h2"
                        className="mb-8 xl:mb-12"
                      />
                    )}
                    desktopImageWidth="xl:w-1/2"
                    desktopTitleWidth="xl:w-1/2"
                    desktopTitleVerticalPosition="center"
                    content={(
                      <>
                        <p dangerouslySetInnerHTML={{ __html: product.description }} className="mt-8 xl:mt-0" />
                        <Link to={`/products/${product.slug}`} className="button-primary inline-block mt-8">MEHR</Link>
                      </>
                    )}
                  />
                </div>
              );
            })}
          </div>
        );
      }}
    </LayoutConsumer>
  );
}

export default ProductPage;

export const productsQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allProductsYaml {
      edges {
        node {
          title
          slug
          description
          link
          image {
            childImageSharp {
              gatsbyImageData(
                width: 720,
                placeholder: BLURRED,
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`;
